//引入
import { JSEncrypt } from 'jsencrypt';
/**
 * 加密
 * @param {String}  需要加密的参数
 */

export function passwordEncryption(param) {
  // 后台给的公钥
  let publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC3CQy9ZWHcg4+II4xPTNq+ai/v6bjsCy4kZlGwuSzM+B2gxEjGeVoICF7sLGclN0fyrU33VLofP4b4rV2ZDKYqwJZR56ffe4vUiCrhYTgQLm30zg9HZRSfK/08Yb3gaH+ez7hNN4FkurLq6MrhY46cVaXq+LHmeZCszDkb4M4lDQIDAQAB';
  let encryptor = new JSEncrypt(); // 新建JSEncrypt对象

  encryptor.setPublicKey(publicKey); // 设置公钥

  let passwordEncryp = encryptor.encrypt(param); // 对密码进行加密

  return passwordEncryp;
} // 解密

export function decrypt(msg) {
  const privateKey = 'MIICXAIBAAKBgQC3CQy9ZWHcg4+II4xPTNq+ai/v6bjsCy4kZlGwuSzM+B2gxEjGeVoICF7sLGclN0fyrU33VLofP4b4rV2ZDKYqwJZR56ffe4vUiCrhYTgQLm30zg9HZRSfK/08Yb3gaH+ez7hNN4FkurLq6MrhY46cVaXq+LHmeZCszDkb4M4lDQIDAQABAoGBAK01yDOXNHPcoECh+060GTEnBfRQxqnBrsnNdjL6GdZiWo9gEMqZ3kRJv6JaJsYGOXUC4gxTxsp5cL32OePzH28t5GUXlTqG2rUfV8VWwa8nkP5ZlQY8yWsqLaB8NCQCbs8/lp5Kh7pcb+OBb2Zhr3O7gJ/GNgDExqynVa6YTT+BAkEA37Hyvy70PftKFj6dn0jdjIrck7PEcizz9ZmryADjKCRBA1NE+tSxBZ8S20bCqqEsAAxm84YefFm8jg1120Rn4QJBANF35v2ZmzLYBX1KjW2CUN8ReG+CWbWH1aoBIaFeLowFBeiJz6LuhQnB9BMq2oogp4fz7NAAjij2hdqOzliKMq0CQGi/6n1OXn8byjCqd6McOurSJ8JenvIcTM1IfaF1kOm+OFd3ScjvikmomOrnssa2HGWVaSxGNMZkOyCF+xeHa+ECQFX3wLyaG0ENuYsHWu3zyt8SG3ygYdMH8Z/5IOVKcw4STa93R9lVzRIJIPPw5YpFBpzcdneTksSrEJp3e6aI6W0CQAVIyXrtCf9lbVc6oSZgktDxW/ry/AkRdvxndTfbsj9ZPSDlhWQdvSFKv1mDjOdAXcs1QpHDvMlhT5oUiBIwydM=';
  let decrypt = new JSEncrypt();
  decrypt.setPrivateKey(privateKey);
  var decryptMsg = decrypt.decrypt(msg);
  return decryptMsg;
}