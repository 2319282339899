<!--
 * @Author: your name
 * @Date: 2021-06-30 07:58:39
 * @LastEditTime: 2021-07-10 07:05:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-uscenter/src/views/Login.vue
-->
<template>
  <v-app>
    <v-main app>
      <!-- 给应用提供合适的间距 -->
      <v-container fluid>
        <Vcode
          :show="isShow"
          :canvasWidth="vWidth"
          @success="onSuccess"
          @close="onClose"
        />
        <div class="d-flex justify-center">
          <!-- 如果使用 vue-router -->
          <v-card id="loginBox" max-width="380" width="100%" class="ma-3">
            <v-card-title>
              <v-icon class="pr-2" style="font-size: 30px"
                >mdi-account-circle-outline</v-icon
              >
              系统登录
            </v-card-title>
            <v-divider></v-divider>
            <v-tabs
              icons-and-text
              v-model="tab"
              :background-color="
                tab < 1
                  ? 'primary darken-1'
                  : tab == 2
                  ? 'orange darken-4'
                  : 'success darken-2'
              "
              dark
              grow
              @change="tabClick"
            >
              <v-tab v-for="(item, x) in tabs" :key="x" :disabled="item.disabled">
                {{ item.text }}
                <v-icon>{{ item.icon }}</v-icon>
              </v-tab>
            </v-tabs>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-tabs-items v-model="tab">
                <v-tab-item :key="0">
                  <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-card-text class="pt-10 px-6">
                      <validation-provider
                        name="登录ID"
                        v-slot="{ errors }"
                        rules="required|min:4|max:18"
                      >
                        <v-text-field
                          v-model="userpasswd.name"
                          prepend-inner-icon="mdi-account"
                          style="font-size: 25px"
                          label="登录ID/手机号码/USID"
                          required
                          :error-messages="errors"
                          outlined
                        >
                        </v-text-field>
                      </validation-provider>

                      <validation-provider
                        name="密码"
                        v-slot="{ errors }"
                        rules="required|min:6|max:18|password"
                      >
                        <v-text-field
                          prepend-inner-icon="mdi-key"
                          v-model="userpasswd.password"
                          :append-icon="userpasswd.show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="userpasswd.show ? 'text' : 'password'"
                          name="input-10-1"
                          label="登录密码"
                          counter
                          @click:append="userpasswd.show = !userpasswd.show"
                          class="pb-4"
                          :error-messages="errors"
                          outlined
                        ></v-text-field>
                      </validation-provider>

                      <v-checkbox
                        v-if="weChatOpenid"
                        label="与微信账号绑定"
                        v-model="linkWechat"
                        :value="1"
                        class="py-0 my-0"
                      ></v-checkbox>

                      <v-row>
                        <v-col cols="12" class="pr-3">
                          <v-btn
                            :color="tab < 1 ? 'primary' : 'success'"
                            darken
                            block
                            @click="showvcode()"
                            class="pb-5 pt-5"
                            :disabled="invalid"
                            large
                          >
                            <v-icon class="mr-3">mdi-check-circle-outline</v-icon>
                            登录社区
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-checkbox
                        color="grey darken-2"
                        v-model="userpasswd.checkbox"
                        label="记住我的登录ID?"
                        required
                      ></v-checkbox>
                    </v-card-text>
                  </validation-observer>
                </v-tab-item>

                <v-tab-item :key="1">
                  <v-card-text>
                    <v-text-field
                      prepend-inner-icon="mdi-cellphone"
                      v-model="mobile.phone"
                      :counter="11"
                      style="font-size: 25px"
                      :rules="mobile.nameRules"
                      label="手机号码"
                      required
                    >
                    </v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :color="tab < 1 ? 'primary' : 'success'"
                      darken
                      :disabled="sendBtn"
                      block
                      @click="showvcode"
                      class="pb-5 pt-5"
                    >
                      <v-icon left>
                        {{ sendIcon }}
                      </v-icon>
                      <span>{{ sendText }}</span>
                    </v-btn>
                  </v-card-actions>
                  <v-card-text>
                    <v-checkbox
                      v-if="weChatOpenid"
                      color="success darken-2"
                      label="与微信账号绑定"
                      v-model="linkWechat"
                      :value="1"
                      class="py-0 my-0"
                    ></v-checkbox>
                    <v-otp-input
                      v-model="mobile.code"
                      @finish="loginFormMobile()"
                      length="4"
                      style="width: 210px; margin: 0px auto"
                    ></v-otp-input>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item :key="2">
                  <v-card-text
                    v-if="qrcode.code.length > 0 ? true : false"
                    align="center"
                  >
                    <vue-qr :text="qrcode.code" :size="qrcode.size"></vue-qr>
                  </v-card-text>
                  <v-card-text class="text-center py-2">
                    截止倒计时: {{ 30 - wxTmpTime }}
                  </v-card-text>
                  <v-card-text
                    class="text-center subtitle-1 py-2"
                    v-if="userWechat == true"
                  >
                    <div class="py-4">
                      <v-btn elevation="0" small fab color="success">
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </div>
                    已经扫码，请在微信端点击确认登陆按钮
                  </v-card-text>
                  <v-card-text>
                    <v-alert dense border="left" type="warning">
                      请使用微信扫码登录
                    </v-alert>
                  </v-card-text>
                </v-tab-item>
              </v-tabs-items>
            </v-form>

            <v-card-actions class="py-4">
              <v-btn
                :disabled="!valid"
                color="success darken-3"
                class="mr-4 pl-3"
                text
                @click="
                  $router.push({
                    path: '/reg',
                  })
                "
              >
                <v-icon class="pr-2">mdi-account-multiple-plus-outline</v-icon>
                注册
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="resetValidation"> 忘记密码 </v-btn>
            </v-card-actions>
          </v-card>
        </div>

        <p class="text-center">粤ICP备14015662号-2</p>

        <!--
        <vue-qr :text="loginUrl" :size="300"></vue-qr>
-->
        <v-snackbar :timeout="3000" :color="snackbar.color" v-model="snackbar.model" top>
          {{ snackbar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="#fff" text v-bind="attrs" @click="snackbar.model = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import vueQr from "vue-qr";
import Vcode from "vue-puzzle-vcode";
import { passwordEncryption } from "../plugins/encrypt.js";

//import VueSimpleVerify from "vue-simple-verify";
//import "../../node_modules/vue-simple-verify/dist/vue-simple-verify.css";

export default {
  components: {
    vueQr,
    Vcode,
    //VueSimpleVerify,
  },
  data: () => ({
    loginUrl: "",
    isShow: false,
    vWidth: 310,
    tab: 0,
    tabs: [
      {
        text: "密码登录",
        icon: "mdi-key",
        disabled: false,
      },
      {
        text: "短信验证登录",
        icon: "mdi-cellphone-key",
        disabled: false,
      },
      {
        text: "微信登录",
        icon: "mdi-qrcode-scan",
        disabled: false,
      },
    ],
    state: "",
    snackbar: {
      color: "error",
      model: false,
    },
    valid: true,
    weChatOpenid: "",
    mobile: {
      phone: "",
      code: "",
      nameRules: [
        (v) => !!v || "登录ID/手机号码/USID不能为空",
        //(v) => (v && v.length <= 12) || "不可以大于12个字符",
        (v) => (v && v.length > 5) || "不可以小于6个字符",
      ],
    },
    linkWechat: 1,
    userpasswd: {
      name: "",
      nameRules: [
        (v) => !!v || "登录ID/手机号码/USID不能为空",
        //(v) => (v && v.length <= 12) || "不可以大于12个字符",
        (v) => (v && v.length > 4) || "不可以小于5个字符",
      ],
      //select: null,
      show: false,
      password: "",
      rules: {
        required: (value) => !!value || "登录密码不能为空.",
        min: (v) => v.length >= 6 || "密码不可以少于6个字符",
        emailMatch: () => `密码不能为空`,
      },
      checkbox: true,
    },
    weChatUnid: "",
    sendBtn: false,
    sendText: "发送验证码",
    sendIcon: "mdi-send",
    qrcode: {
      size: "200",
      code: "",
    },
    userWechat: {},
    wxTmpTime: 0,
    wxTmpObj: Object,
    showPassError: false,
  }),
  mounted() {
    if (this.$sopen.isWechat() == true) {
      this.tabs.splice(2, 1);
    }
    this.onResize();
    window.addEventListener("resize", this.onResize, {
      passive: true,
    });
  },
  created() {
    localStorage.removeItem("goback");
    localStorage.removeItem("sopenCheckcode");
    this.loginUrl = document.location.protocol + "//" + window.location.host + "/login";
    this.weChatOpenid = localStorage.getItem("weChatOpenid");

    this.state = this.$route.query.state ? this.$route.query.state : "/";
    let userid = localStorage.getItem("userid");
    if (!isNaN(userid)) {
      this.userpasswd.name = userid;
    }
    let usCookie = this.$sopen.getCookie();
    
    if (usCookie != false) {
      this.$router.push({
        path: "/token",
        query: {
          openuid: usCookie.urec,
          state: encodeURIComponent(this.state),
        },
      });
    }
  },
  methods: {
    onSuccess() {
      this.isShow = false; // 通过验证后，需要手动关闭模态框
      this.loginForm();
    },
    onClose() {
      this.isShow = false;
    },
    wxqrcode() {
      let data = {
        module: "loginsys",
        title: "wxloginApp",
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        that.weChatUnid = res.data.loginsys.unid;
        that.qrcode.code =
          document.location.protocol +
          "//" +
          window.location.host +
          "/weChat/authorize-login?u=" +
          that.weChatUnid;
        that.wxTime();
      });
    },
    wxTime(x = 0) {
      if (x > 30) {
        this.tab = 0;
        clearTimeout(this.wxTmpObj);
        this.wxTmpTime = 0;
        return false;
      }
      this.wxTmpTime = x;
      x++;

      this.wxReadCookie();
      let that = this;
      this.wxTmpObj = setTimeout(() => {
        that.wxTime(x);
      }, 1000);
    },
    wxReadCookie() {
      let data = {
        module: "loginsys",
        title: "wxloginck",
        data: {
          unid: this.weChatUnid,
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        that.userWechat = res.data.loginsys;
        if (that.userWechat && that.userWechat["cookie"] != undefined) {
          that.$sopen.setCookie("usCookie", that.userWechat.cookie);
          that.reusrec();
          clearTimeout(that.wxTmpObj);
        } else {
          return that.userWechat;
        }
      });
    },
    onResize() {
      let loginBox = document.getElementById("loginBox");
      this.vWidth = loginBox.clientWidth - 40;
      this.qrcode.size = window.innerWidth > 420 ? 300 : window.innerWidth - 100;
    },
    sendTime() {
      let timeOut = 60;
      let that = this;
      let d = new Date();
      let t = d.getTime();
      let sendTime = localStorage.getItem("sendTime");

      if (sendTime != "NaN") {
        sendTime = parseInt(sendTime);
      }

      if (isNaN(sendTime)) {
        let v = t + timeOut * 1000;
        localStorage.setItem("sendTime", v);
        this.sendBtn = true;
        for (let x in this.tabs) {
          this.tabs[x]["disabled"] = true;
        }
        this.sendTime();
      } else if (parseInt(t) > sendTime + 1000) {
        this.sendText = "发送验证码";
        this.sendBtn = false;
        for (let x in this.tabs) {
          this.tabs[x]["disabled"] = false;
        }
        localStorage.removeItem("sendTime");
      } else {
        this.sendBtn = true;
        let no = Math.ceil((sendTime - t) / 1000);
        this.sendText = "发送中(" + no + ")...";
        setTimeout(function () {
          that.sendTime();
        }, 1000);
        return false;
      }
    },
    sendCode() {
      this.$refs.form.validate();
      if (this.mobile.phone.length < 1) {
        this.snackbar = {
          color: "",
          text: "手机号码不能为空？",
          model: true,
        };
        return false;
      }

      let data = {
        module: "user",
        title: "mobilecode2",
        data: {
          mobile: this.mobile.phone,
        },
      };
      this.isShow = false;
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        if (res.data.user.errcode == 0) {
          that.sendTime();
        } else {
          that.snackbar = {
            color: "",
            text: res.data.user.errmsg,
            model: true,
          };
        }
      });
    },
    showvcode() {
      this.isShow = true;
    },
    loginForm() {
      if (this.tab == 0) {
        this.loginFormPasswd();
      } else if (this.tab == 1) {
        this.sendCode();
      }
    },
    loginFormPasswd() {
      this.$refs.form.validate();
      this.snackbar.model = false;
      if (this.userpasswd.name.length < 5 || this.userpasswd.password < 6) {
        this.snackbar = {
          color: "",
          text: "登录ID与登录密码不能为空",
          model: true,
        };
        return false;
      }
      if (this.userpasswd.checkbox === true) {
        localStorage.setItem("userid", this.userpasswd.name);
      }

      let userPasswd = {
        userid: this.userpasswd.name,
        passwd: passwordEncryption(this.userpasswd.password),
        openid: this.weChatOpenid != undefined ? JSON.parse(this.weChatOpenid) : {},
      };
      let that = this;
      this.$sopen.login(userPasswd).then(function (res) {
        if (res.errcode < 1) {
          that.$sopen.setCookie("usCookie", res.cookie);
          //localStorage.setItem("usCookie", res.cookie);
          that.reusrec();
        } else {
          that.snackbar = {
            color: "error",
            text: res.errmsg,
            model: true,
          };
          return false;
        }
      });
    },
    loginFormMobile() {
      this.$refs.form.validate();
      this.snackbar.model = false;
      if (this.mobile.phone.length < 5 || this.mobile.code.length < 4) {
        this.snackbar = {
          color: "",
          text: "手机号码与手机验证码不能为空不能为空",
          model: true,
        };
        return false;
      }
      let data = {
        module: "user",
        title: "loginMobileCode",
        data: {
          mobile: this.mobile.phone,
          mobilecode: this.mobile.code,
          openid: this.weChatOpenid != undefined ? JSON.parse(this.weChatOpenid) : {},
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        console.log(res);
        if (res.data.user.errcode < 1) {
          that.$sopen.setCookie("usCookie", res.data.user.cookie);
          //that.reusrec();
        } else {
          that.snackbar = {
            color: "error",
            text: res.data.user.errmsg,
            model: true,
          };
          return false;
        }
      });
    },
    reusrec(targetid = "") {
      let tokenValue = this.$sopen.getCookie("usCookie");
      let data = {
        module: "usinfo",
        title: "reusrec",
        data: {
          cookie: JSON.stringify(tokenValue),
          targetid: targetid,
          url:
            document.location.protocol +
            "//" +
            (window.location.host.indexOf("schoolopen") > -1
              ? window.location.host
              : "luohu.sopen.cn"),
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        console.log(res);
        if (res.data.usinfo.usinfo.TARGETID != "") {
          that.$router.push({
            path: "/token",
            query: {
              openuid: res.data.usinfo.openuid,
              state: encodeURIComponent(that.state),
            },
          });
        } else {
          that.$router.push({
            path: "/user",
          });
        }
      });
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    success() {
      if (this.tab === 1 && this.mobile.phone.length > 6) {
        this.sendBtn = false;
      }
    },
    toggleMarker() {},
    tabClick() {
      this.sendBtn = true;
      if (this.tab == 2 && this.wxTmpTime < 1) {
        this.wxqrcode();
      }
    },
  },
  watch: {
    mobile: {
      handler(val) {
        this.sendBtn = val.phone.length == 11 ? false : true;
      },
      immediate: true, //刷新加载 立马触发一次handler
      deep: true, // 可以深度检测到 obj 对象的属性值的变化
    },
  },
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
